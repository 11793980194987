$(document).ready(function() {
  $('.footer_image').on('mouseenter', function() {
    new FooterImage($(this)).color();
  });

  $('.footer_image').on('mouseleave', function() {
    new FooterImage($(this)).grey();
  });
});


class FooterImage {
  constructor(params) {
    this.params = params;
  }

  color() {
    this.params.attr('src', this.params.data('color'));
  }

  grey() {
    this.params.attr('src', this.params.data('grey'));
  }
}
