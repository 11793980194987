$(document).ready(function() {
  if ($('.menu-item').data('scroll')) {
    new Index($('.menu-item')).call();

    $('.menu-item').hover(function() {
      new Index($(this)).call();
    });
  }
});

class Index {
  constructor(params) {
    this.params = params;
    this.imageExp = $('#mainNav').data('imageExpPath');
    this.image = $('#mainNav').data('imagePath');
  }

  call() {
    this.onLoadMain(this.params.data('index'));
    this.onMouseOver(this.params.data('index'), this.params.data('klass'));
  }

  onLoadMain(Index) {
    document.getElementById('menu_' + Index).style.background = this.image;
  };

  onMouseOver(Index, Class) {
    var divs = document.getElementsByClassName('innerPage');
    for (var i = 0; i < divs.length; i++) {
      divs[i].style.display = 'none';
    }
    try {
      document.getElementById(Index).style.display = '';
    } catch (err) { }
    var divs = document.getElementsByClassName('menu-item');
    for (var i = 0; i < divs.length; i++) {
      divs[i].style.background = null;
    }
    if (Class == 'expanded') {
      document.getElementById('menu_' + Index).style.background = this.imageExp;
    } else {
      document.getElementById('menu_' + Index).style.background = this.image;
    }
  }
}
